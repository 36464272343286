import { DeleteOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { errorMessage } from '../helpers/message';
import { downloadBlobFile } from '../helpers';

import '../assets/styles/components/upload-file.less';

const filesMimeTypes = {
    word: 'application/msword',
    pdf: 'application/pdf',
    csv: 'application/csv',
    excel: 'application/vnd.ms-excel',
    jpeg: 'image/jpeg',
    png: 'image/png',
};
interface UploadFileProps {
    value?: any;
    onChange?: (value: RcFile | undefined) => void;
    type?: 'button' | 'dragger';
    buttonLabel?: string;
    setRemoveFile?: (value: string) => void;
    fileType?: 'document' | 'pdf' | 'images';
    fileTypeText?: string;
    maxSize?: number;
    noNeedFileRemove?: boolean;
    noNeedFileDownload?: boolean;
}

const UploadFile: React.FC<UploadFileProps> = ({
    onChange,
    value,
    type,
    buttonLabel,
    setRemoveFile,
    fileType,
    fileTypeText,
    maxSize,
    noNeedFileDownload,
    noNeedFileRemove,
}) => {
    const { formatMessage } = useIntl();
    const [file, setFile] = useState<RcFile>();
    const [showFileUpload, setShowFileUpload] = useState(true);

    if (!type) {
        type = 'dragger';
    }

    // ---------------------------------------
    // Prepare thumbnail upload file

    const prepareLogoUpload: DraggerProps['beforeUpload'] = (newFile) => {
        if (fileType) {
            switch (fileType) {
                case 'document':
                    if (!Object.values(filesMimeTypes).includes(newFile.type)) {
                        errorMessage({
                            content: formatMessage(
                                {
                                    id: 'uploadFile.uploadError.multiFileTypes',
                                    defaultMessage: 'Seuls les fichiers "{fileFormats}" sont autorisés',
                                    description: 'upload error',
                                },
                                { fileFormats: Object.keys(filesMimeTypes).toString() }
                            ),
                        });
                        return false;
                    }
                    break;
                case 'pdf':
                    if (!['application/pdf'].includes(newFile.type)) {
                        errorMessage({
                            content: formatMessage({
                                id: 'uploadFile.uploadError.onlyPDF',
                                defaultMessage: 'Seuls les fichiers PDF sont autorisés',
                                description: 'upload error',
                            }),
                        });
                        return false;
                    }
                    break;
                case 'images':
                    if (!['image/jpeg', 'image/png'].includes(newFile.type)) {
                        errorMessage({
                            content: formatMessage({
                                id: 'uploadFile.uploadError.onlyimages',
                                defaultMessage: 'Seuls les images sont autorisés',
                                description: 'upload error',
                            }),
                        });
                        return false;
                    }
                    break;
            }
        }

        if (maxSize) {
            if (newFile.size / 1024 > maxSize * 1000) {
                errorMessage({
                    content: formatMessage(
                        {
                            id: 'uploadFile.uploadError.maximumSize',
                            defaultMessage: 'Votre fichier dépasse la limite de taille maximum de {size}Mo',
                            description: 'upload error',
                        },
                        { size: maxSize }
                    ),
                });
                return false;
            }
        }

        setFile(newFile);
        onChange?.(newFile);
        return false;
    };

    const onFileDownload = () => {
        if (file) {
            downloadBlobFile(file.name, file, '');
        } else {
            window.open(value.url, '_blank');
        }
    };

    const onFileRemove = () => {
        setRemoveFile?.(value.filename);
        setFile(undefined);
        setShowFileUpload(true);
        onChange?.(undefined);
    };

    useEffect(() => {
        setShowFileUpload(!value);
    }, [value]);

    return (
        <>
            {showFileUpload && !file ? (
                <>
                    {type === 'dragger' ? (
                        <Dragger
                            beforeUpload={prepareLogoUpload}
                            multiple={false}
                            listType="picture"
                            showUploadList={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                <FormattedMessage
                                    id="uploadFile.intro.toDeposeFile"
                                    defaultMessage="Cliquez-ici ou déposez un fichier"
                                />
                            </p>
                            <p className="ant-upload-hint">
                                {fileTypeText ?? (
                                    <FormattedMessage
                                        id="uploadFile.fileFormat"
                                        defaultMessage="Fichier au format pdf"
                                    />
                                )}
                            </p>
                        </Dragger>
                    ) : (
                        <Upload beforeUpload={prepareLogoUpload} listType="picture" showUploadList={false}>
                            <Button shape="round" size="small">
                                {buttonLabel ?? (
                                    <FormattedMessage
                                        id="uploadFile.action.clickHere"
                                        defaultMessage="Cliquez-ici ou déposez un fichier"
                                    />
                                )}
                            </Button>
                        </Upload>
                    )}
                </>
            ) : (
                <div className="uploaded-file bg-lightish-grey">
                    <div className="filename">{file?.name ?? value.filename}</div>
                    <div className="actions">
                        {!noNeedFileDownload && (
                            <Button type="text" icon={<DownloadOutlined />} onClick={onFileDownload} />
                        )}
                        {!noNeedFileRemove && <Button type="text" icon={<DeleteOutlined />} onClick={onFileRemove} />}
                    </div>
                </div>
            )}
        </>
    );
};

export default UploadFile;
